import { Divider } from '@outdoorsyco/bonfire';
import { clsx } from 'clsx';
import querystring from 'query-string';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import odnAdImage from '@/assets/advertisement-tile/odn-ad.jpg';
import adTileImage from '@/assets/advertisement-tile/outdoorsy-stays-ad.png';
import RentalsCarousel from '@/components/common/SimilarRentalsCarousel/RentalsCarousel';
import NoResultsMessage from '@/components/route/search/AlertMessage/NoResultsMessage';
import OwnerResultsMessage from '@/components/route/search/AlertMessage/OwnerResultsMessage';
import VehicleNoResultsMessage from '@/components/route/search/AlertMessage/VehicleNoResultsMessage';
import { useSearchResultsCtx } from '@/components/route/search/SearchResults/SearchResults.context';
import Heading from '@/components/switchback/Heading/Heading';
import Link from '@/components/switchback/Link/Link';
import Fade from '@/components/switchback/Transition/Fade';
import { EListingTileVariant, ListingTile } from '@/components/ui/ListingTile';
import { HILL_COUNTRY_ODN_ID } from '@/constants/campgroundODN';
import { ESearchFilters } from '@/constants/searchFilters';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useQueryParams from '@/hooks/useQueryParams';
import { useRouter } from '@/hooks/useRouter';
import {
  triggerDeliveryFilterFromAd,
  triggerStationaryDeliveryFilter,
} from '@/redux/modules/search';
import { getQueryParams } from '@/redux/selectors/queryParams';
import {
  getIsLoading,
  isCampgroundSearchResult,
  TSearchResult,
} from '@/redux/selectors/search/searchResults';
import { IWishlistingEventData } from '@/services/analytics/listings/types';
import { ERentalType } from '@/services/analytics/types';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import {
  ERentalCategory,
  INearbyCampgrounds,
  INearbyCampgroundsForDelivery,
} from '@/services/types/search/rentals/id';
import {
  ICampgroundTile,
  IRentalTile,
  isCampgroundTile,
  ISearchResultTile,
} from '@/utility/mapSearchResultToTile';

import { AdvertisementTile } from '../AdvertisementTile/AdvertisementTile';
import PaginationContainer from '../PaginationContainer/PaginationContainer';
import { NearbyResultsCountContainer } from '../ResultsCount/ResultsCountContainer';
import css from './SearchResults.module.css';
import { useStationaryCamperPromo } from './useStationaryCamperPromo';

type ISearchResultsElement = React.HTMLAttributes<HTMLElement>;

interface ITileProps {
  isMapOpen?: boolean;
  wishlistingEventData?: IWishlistingEventData;
  onClickRental: (rental: ISearchResultTile, index: number) => void;
  onChangeImage: (rental: ISearchResultTile, index: number, nextIndex: number) => void;
}

interface IProps extends ITileProps {
  ownerName?: string;
  searchResult: TSearchResult;
  onOwnerButtonClick: () => void;
  isStayTab?: boolean;
  isCampgroundTab?: boolean;
  isPromoPage?: boolean;
}

interface IResultsProps extends ITileProps {
  hasResults?: boolean;
  rentals?: IRentalTile[] | ICampgroundTile[];
  randomStays?: IRentalTile[];
  isStayTab?: boolean;
  isCampgroundTab?: boolean;
  isPromoPage?: boolean;
  showStationaryCamperPromo?: boolean;
  shouldShowProBadge?: boolean;
  nearbyCampgroundsForDelivery?: INearbyCampgroundsForDelivery | null;
  nearbyCampgrounds?: INearbyCampgrounds | null;
}

const TilesGrid: React.FC<IResultsProps> = ({
  rentals,
  isMapOpen,
  onClickRental,
  onChangeImage,
  isPromoPage = false,
  showStationaryCamperPromo = false,
  shouldShowProBadge = false,
  nearbyCampgrounds = null,
  nearbyCampgroundsForDelivery = null,
  wishlistingEventData,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const router = useRouter();
  const { isAboveXL, isAboveDesktop, isMobile } = useBreakpoint();
  const { hoveredId, mapHoveredId, updateHoveredId } = useSearchResultsCtx();
  const filtersFromQuery = useSelector(getQueryParams);
  const rentalCategoryFromQuery =
    ((filtersFromQuery && filtersFromQuery[ESearchFilters.RENTAL_CATEGORY]) as ERentalCategory) ||
    ERentalCategory.RV;
  const isRv = rentalCategoryFromQuery === ERentalCategory.RV;
  const singleTileGridExperiment = useExperimentIsEnabled(OptimizelyFlags.SINGLE_TILE_GRID);
  const singleTileGridEnabled = singleTileGridExperiment && isAboveXL && isRv && !isPromoPage;

  const handleMouseEnter = (rental: ISearchResultTile) => updateHoveredId(rental.id || null);
  const { stationaryCamperRentalId, customRentalTitle, hasStationaryCamperInFirstSix } =
    useStationaryCamperPromo(rentals);

  const handleMouseLeave = () => updateHoveredId(null);

  const outdoorsyStaysExperimentEnabled = useExperimentIsEnabled(OptimizelyFlags.OUTDOORSY_STAYS);
  const deliveryStationaryFromQuery = filtersFromQuery[ESearchFilters.DELIVERY_STATIONARY];

  const superhostBadgeEnabled = useExperimentIsEnabled(OptimizelyFlags.SUPERHOST_BADGE);

  const showAdvertisementTile =
    outdoorsyStaysExperimentEnabled &&
    isRv &&
    !singleTileGridEnabled &&
    !deliveryStationaryFromQuery;

  const advertisement = useMemo(() => {
    if (!showAdvertisementTile) return null;

    if (nearbyCampgroundsForDelivery?.data.length) {
      return {
        heading: intl.formatMessage({
          defaultMessage: 'Book your RV & campsite, we’ll have it set up when you arrive.',
          id: 'Y0OSQW',
        }),
        linkLabel: intl.formatMessage({ defaultMessage: 'See what’s nearby', id: 'MYZbVk' }),
        image: adTileImage.src,
        onClick: () => {
          dispatch(triggerStationaryDeliveryFilter(true));
          dispatch(triggerDeliveryFilterFromAd(true));
        },
      };
    }

    if (nearbyCampgrounds?.data?.some(campground => campground.odn_id === HILL_COUNTRY_ODN_ID)) {
      return {
        heading: intl.formatMessage({
          defaultMessage: 'Discover luxury glamping in the heart of Texas Hill Country',
          id: 'JMP8CJ',
        }),
        linkLabel: intl.formatMessage({ defaultMessage: 'Book a stay', id: 'hakMYM' }),
        image: odnAdImage.src,
        onClick: () => {
          router.push('/destinations/outdoorsy-hill-country');
        },
        odn_id: HILL_COUNTRY_ODN_ID,
      };
    }
  }, [
    dispatch,
    intl,
    nearbyCampgrounds?.data,
    nearbyCampgroundsForDelivery?.data.length,
    router,
    showAdvertisementTile,
  ]);

  if (!rentals?.length) return null;

  return (
    <ul
      className={clsx('grid gap-5 grid-cols-1 sm:grid-cols-2 mb-11 lg:mb-12', {
        'lg:grid-cols-3': !singleTileGridEnabled,
        'lg:grid-cols-1': singleTileGridEnabled,
      })}
      data-map-open={String(isMapOpen || false)}>
      {advertisement && <AdvertisementTile {...advertisement} />}
      {rentals.map((tile, index) => {
        const displayStationaryCamperPromo =
          tile.id === stationaryCamperRentalId &&
          hasStationaryCamperInFirstSix &&
          showStationaryCamperPromo;
        return (
          <li key={tile.id || index}>
            <Fade duration={150}>
              {isCampgroundTile(tile) ? (
                <ListingTile
                  rentalTile={tile}
                  isOutlined={mapHoveredId === tile.id || hoveredId === tile.id}
                  onSlideChange={nextIndex => onChangeImage(tile, index, nextIndex)}
                  target={isAboveDesktop ? '_blank' : undefined}
                  onClick={() => onClickRental(tile, index)}
                  onMouseEnter={() => handleMouseEnter(tile)}
                  onMouseLeave={() => handleMouseLeave()}
                  withPriority={!isMobile || (isMobile && index < 2)}
                  withFetchPriority={isMobile && index < 2 ? 'high' : undefined}
                  ssrNavigation={false}
                  wishlistingEventData={{ index, ...wishlistingEventData }}
                />
              ) : (
                <ListingTile
                  rentalTile={tile}
                  isOutlined={mapHoveredId === tile.id || hoveredId === tile.id}
                  onSlideChange={nextIndex => onChangeImage(tile, index, nextIndex)}
                  target={isAboveDesktop ? '_blank' : undefined}
                  onClick={() => onClickRental(tile, index)}
                  onMouseEnter={() => handleMouseEnter(tile)}
                  onMouseLeave={() => handleMouseLeave()}
                  dataTestId="search-result"
                  displayStationaryCamperPromo={displayStationaryCamperPromo}
                  customRentalTitle={customRentalTitle || tile.title}
                  variant={
                    singleTileGridEnabled
                      ? EListingTileVariant.Horizontal
                      : EListingTileVariant.Vertical
                  }
                  showSuperhost={superhostBadgeEnabled}
                  showGuestFavorite
                  showProBadge={shouldShowProBadge && tile.dealer}
                  withPriority={!isMobile || (isMobile && index < 2)}
                  withFetchPriority={isMobile && index < 2 ? 'high' : undefined}
                  ssrNavigation={false}
                  wishlistingEventData={{ index, ...wishlistingEventData }}
                />
              )}
            </Fade>
          </li>
        );
      })}
    </ul>
  );
};

const NoSearchResults: React.FC<IResultsProps> = ({
  hasResults,
  isMapOpen,
  rentals,
  randomStays,
  onClickRental,
  onChangeImage,
  isStayTab,
  isCampgroundTab,
}) => {
  const intl = useIntl();
  const queryParams = useQueryParams();
  const showMoreStaysText = intl.formatMessage({
    defaultMessage: 'Show more available stays',
    id: 'Aef6Ni',
  });
  const showMoreRVsText = intl.formatMessage({
    defaultMessage: 'Show more RVs',
    id: 'hsUwP6',
  });
  const showMoreDeliverableRVsText = intl.formatMessage({
    defaultMessage: 'Show more deliverable RVs',
    id: '43hBvv',
  });

  const similarQuery = {
    [ESearchFilters.ADDRESS]: queryParams[ESearchFilters.ADDRESS],
    [ESearchFilters.DELIVERY_ADDRESS]: queryParams[ESearchFilters.DELIVERY_ADDRESS],
    [ESearchFilters.BOUNDS_NE]: queryParams[ESearchFilters.BOUNDS_NE],
    [ESearchFilters.BOUNDS_SW]: queryParams[ESearchFilters.BOUNDS_SW],
  };

  const deliveryQuery = {
    [ESearchFilters.DELIVERY]: 'true',
    ...similarQuery,
  };

  // This page will look pretty similar for both Stays and RVs since we want to render
  // filler content (random stays, deliverable RVs) for both in the case of low inventory.
  const isStayOrCampgroundsTab = isStayTab || isCampgroundTab;

  const ShowMoreStaysLink =
    !isStayOrCampgroundsTab || !randomStays?.length ? null : (
      <Link
        href={`/rv-search?${ESearchFilters.RENTAL_CATEGORY}=${ERentalType.STAY}&${ESearchFilters.ADDRESS}=United States`}
        className={`block mt-3 ${rentals?.length ? '' : 'mb-7'}`}>
        <span className="text-base font-medium autoType500">{showMoreStaysText}</span>
      </Link>
    );

  const ShowMoreRVsLink = !rentals?.length ? null : (
    <Link
      href={`/rv-search?${querystring.stringify(isStayTab ? deliveryQuery : similarQuery)}`}
      className="block mb-7">
      <span className="text-base font-medium autoType500">
        {isStayOrCampgroundsTab ? showMoreDeliverableRVsText : showMoreRVsText}
      </span>
    </Link>
  );

  const NoResultsMsg = hasResults ? null : isStayOrCampgroundsTab ? (
    <NoResultsMessage
      hasNearby={!!rentals?.length || !!randomStays?.length}
      isStay={isStayTab}
      isCampground={isCampgroundTab}
    />
  ) : (
    <VehicleNoResultsMessage hasNearby={!!rentals?.length} />
  );

  // Ppossibly empty Campgrounds and Stays tab will use random stays as filler content
  const RandomStaysHeading =
    !isStayOrCampgroundsTab || !randomStays?.length ? null : (
      <>
        <Heading level={2} className={css.randomStaysTitle}>
          <FormattedMessage
            defaultMessage="Stay someplace else"
            id="zW9N1r"
            description="Title for random stays listings on search page"
          />
        </Heading>
      </>
    );

  const RandomStaysCarousel = (
    <RentalsCarousel maxCols={4} inline rentalsList={randomStays || []} loading={false} />
  );

  const NearbyResultsHeading =
    !!rentals?.length &&
    (!isStayOrCampgroundsTab ? (
      <>
        <Divider />
        <div className="flex">
          <Heading level={2} className="mt-10 mb-3 highlight autoType600 md:block">
            <FormattedMessage
              defaultMessage="Here are some results that don’t quite match your search criteria"
              id="rNwwki"
              description="Title for nearby listings on search page"
            />
          </Heading>
          <div className="mt-10 mb-3 md:ml-auto">
            <NearbyResultsCountContainer />
          </div>
        </div>
      </>
    ) : (
      <>
        <Heading
          level={2}
          className="mb-4 text-2xl font-medium border-t mt-9 pt-9 border-neutral-20">
          <FormattedMessage
            defaultMessage="Other travel options"
            id="kSKNv4"
            description="Title for nearby listings on search page"
          />
        </Heading>
        <div className="mb-10">
          <p className="text-justify leading-7">
            <FormattedMessage
              defaultMessage={`A delivered RV is a lot like a Stay. You don’t have to drive or tow it. Instead, let your host deliver and set up the vehicle at a campsite you’ve reserved — or a friend’s back yard you’ve called dibs on.`}
              id="0+IcAH"
            />
          </p>
        </div>
      </>
    ));

  const NearbyResultsTileGrid = (
    <TilesGrid
      isMapOpen={isMapOpen}
      rentals={rentals}
      onClickRental={onClickRental}
      onChangeImage={onChangeImage}
    />
  );

  return (
    <>
      {NoResultsMsg}
      {RandomStaysHeading}
      {RandomStaysCarousel}
      {ShowMoreStaysLink}
      {NearbyResultsHeading}
      {NearbyResultsTileGrid}
      {ShowMoreRVsLink}
    </>
  );
};

const SearchResults: React.FC<IProps & ISearchResultsElement> = ({
  searchResult,
  isMapOpen,
  onOwnerButtonClick,
  ownerName,
  onClickRental,
  onChangeImage,
  isStayTab,
  isCampgroundTab,
  isPromoPage,
  wishlistingEventData,
}) => {
  const isLoading = useSelector(getIsLoading);
  const loadingTiles: IRentalTile[] = Array(24).fill({ loading: true });
  const shouldShowProBadge = !isPromoPage;
  const searchResultData = isCampgroundSearchResult(searchResult)
    ? searchResult.campgrounds.data
    : searchResult.data;
  const nearbyCampgrounds = searchResult.nearbyCampgrounds;
  const nearbyCampgroundsForDelivery = searchResult.nearbyCampgroundsForDelivery;

  return (
    <>
      {!!ownerName && !isCampgroundTab && (
        <OwnerResultsMessage
          ownerName={ownerName}
          onOwnerButtonClick={onOwnerButtonClick}
          isStay={isStayTab}
        />
      )}

      {isLoading ? (
        <TilesGrid
          rentals={loadingTiles}
          isMapOpen={isMapOpen}
          onClickRental={onClickRental}
          onChangeImage={onChangeImage}
          isPromoPage={isPromoPage}
          wishlistingEventData={wishlistingEventData}
        />
      ) : (
        <>
          <TilesGrid
            rentals={searchResultData}
            isMapOpen={isMapOpen}
            onClickRental={onClickRental}
            onChangeImage={onChangeImage}
            isPromoPage={isPromoPage}
            nearbyCampgrounds={nearbyCampgrounds}
            nearbyCampgroundsForDelivery={nearbyCampgroundsForDelivery}
            shouldShowProBadge={shouldShowProBadge}
            showStationaryCamperPromo
            wishlistingEventData={wishlistingEventData}
          />
          {!!searchResult && !isPromoPage && <PaginationContainer />}
          <NoSearchResults
            hasResults={!!searchResultData.length}
            rentals={searchResultData.length <= 8 ? searchResult?.nearby?.data : []}
            randomStays={searchResult?.randomStays?.data}
            isMapOpen={isMapOpen}
            isStayTab={isStayTab}
            isCampgroundTab={isCampgroundTab}
            onClickRental={onClickRental}
            onChangeImage={onChangeImage}
          />
        </>
      )}
    </>
  );
};

export default SearchResults;
