import React from 'react';
import { useSelector } from 'react-redux';

import { getFlexibleDateRangeSelected } from '@/redux/selectors/queryParams';
import {
  trackListingImageViewedEvent,
  trackListingSelectedEvent,
} from '@/services/analytics/listings';
import { EListingSource } from '@/services/analytics/listings/types';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

import CarouselGrid from './Grid';

interface ICarouselProps {
  maxCols?: number;
  inline?: boolean;
  maxRentals?: number;
  verticalScrollGrid?: boolean;
  title?: string;
  loading?: boolean;
  rentalsList: IRentalTile[];
  extraQueryParams?: string;
  eventSource?: EListingSource;
  isRow?: boolean;
}

const RentalsCarousel: React.FC<ICarouselProps> = ({
  title,
  maxCols,
  maxRentals,
  verticalScrollGrid,
  loading,
  rentalsList,
  inline = false,
  extraQueryParams,
  eventSource,
  isRow = false,
}) => {
  const flexibleDateRangeSelected = useSelector(getFlexibleDateRangeSelected);

  const onClickRental = (rental: IRentalTile, index: number) => {
    if (rental?.id && eventSource) {
      trackListingSelectedEvent({
        rental,
        eventSource,
        listingPlacement: index + 1,
        flexibleDateRange: flexibleDateRangeSelected,
      });
    }
  };

  const onChangeImage = (rental: IRentalTile, index: number, nextIndex: number) => {
    if (rental?.id && eventSource) {
      trackListingImageViewedEvent(rental, eventSource, index + 1, nextIndex);
    }
  };

  return (
    <CarouselGrid
      title={title}
      maxCols={maxCols}
      inline={inline}
      maxRentals={maxRentals}
      verticalScrollGrid={verticalScrollGrid}
      rentalsList={rentalsList}
      loading={Boolean(loading)}
      extraQueryParams={extraQueryParams}
      onClickRental={onClickRental}
      onChangeImage={onChangeImage}
      isRow={isRow}
      eventSource={eventSource}
    />
  );
};

export default RentalsCarousel;
