import { Button, EButtonColorVariant, ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import Image from 'next/image';
import React, { useEffect } from 'react';

import { trackPromoBannerClicked, trackPromoBannerViewed } from '@/services/analytics/promo';

type TAdvertisementTileProps = {
  heading: string;
  linkLabel: string;
  image: string;
  onClick: () => void;
  odn_id?: string;
};

export const AdvertisementTile = ({
  heading,
  linkLabel,
  image,
  onClick,
  odn_id,
}: TAdvertisementTileProps) => {
  const handleCLick = () => {
    trackPromoBannerClicked({
      bannerText: heading,
      bannerCTA: linkLabel,
      bannerLink: null,
      bannerName: 'outdoorsyStaysDeliveryMVP',
      source: 'search',
      odn_id,
    });
    onClick();
  };

  useEffect(() => {
    trackPromoBannerViewed({
      bannerText: heading,
      bannerCTA: linkLabel,
      bannerLink: null,
      bannerName: 'outdoorsyStaysDeliveryMVP',
      source: 'search',
      odn_id,
    });
  }, [heading, linkLabel, odn_id]);

  return (
    <li className="relative">
      <div>
        <Image
          className="rounded-2xl"
          src={image}
          alt="Outdoorsy Stays advertisement image"
          role="presentation"
          layout="fill"
          objectFit="cover"
          width={0}
          height={0}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <div className="flex flex-col items-center justify-between h-full p-6 text-center text-white gap-y-2 min-h-[19rem] md:min-h-auto">
        <Text variant={ETextStyleVariant.LargeBold} className="z-10 md:max-w-[15rem]">
          {heading}
        </Text>
        <Button
          onClick={handleCLick}
          label={linkLabel}
          variant={EButtonColorVariant.Tertiary}
          onDark
        />
      </div>
    </li>
  );
};
