import { clsx } from 'clsx';
import isEqual from 'lodash/isEqual';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import querystring from 'querystring';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { FixedFooter } from '@/components/common/footer/FixedFooter';
import ErrorBoundary from '@/components/ErrorBoundary';
import { FilterPillsWrapper } from '@/components/route/search/FilterPillsContext/FilterPillsContext';
import Head from '@/components/route/search/Head/Head';
import SearchResultsCtx from '@/components/route/search/SearchResults/SearchResults.context';
import SearchResultsContainer from '@/components/route/search/SearchResults/SearchResultsContainer';
import Button, { ButtonVariants } from '@/components/switchback/Button/Button';
import Heading from '@/components/switchback/Heading/Heading';
import { LIST, MAP_PIN } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import { breakpoint } from '@/constants/breakpoint';
import { ESearchFilters, SERP_URLS } from '@/constants/searchFilters';
import { useAppLayout } from '@/context/AppLayoutContext';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useFixedFooterVisibility } from '@/hooks/useFixedFooterVisibility';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import useQueryParams from '@/hooks/useQueryParams';
import { useRouter } from '@/hooks/useRouter';
import { useStickyToHeaderBottom } from '@/hooks/useStickyToHeaderBottom';
import useWindowSize from '@/hooks/useWindowSize';
import {
  applySearchFilter,
  getSearchHistogramResults,
  getSearchResults,
} from '@/redux/modules/search';
import { fetchTags } from '@/redux/modules/tags/actions';
import {
  getSearchViewedEventData,
  lastMapPan,
  lastPaginationDelta,
  lastSearchedInSearchBar,
  lastZoomDirection,
  setFilterVehiclesShow,
} from '@/redux/selectors/analytics-selectors';
import { getCurrency } from '@/redux/selectors/currency';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { getSearchResultsMeta } from '@/redux/selectors/search/meta';
import { getPageViewEventData } from '@/redux/selectors/search/page';
import { getSelectedFilter } from '@/redux/selectors/search/pricing';
// ANALYTICS
import { trackMapToggleEvent, trackSearchMapExpandedEvent } from '@/services/analytics/maps';
import { EMapStatus } from '@/services/analytics/maps/types';
import { trackSearchEvent } from '@/services/analytics/search';
import { ERentalType } from '@/services/analytics/types';
import {
  OptimizelyFlags,
  useExperimentIsEnabled,
  useOptimizelyDecision,
} from '@/services/experiments';
import { trackEvent } from '@/services/track-event';
import { ERentalCategory } from '@/services/types/search/rentals/id';
import { getParamAsString } from '@/utility/queryParams';

const SearchMapContainer = dynamic(
  () => import('@/components/route/search/SearchMap/SearchMapContainer'),
  { ssr: false },
);

const ResultsSort = dynamic(() => import('@/components/route/search/ResultsSort/ResultsSort'), {
  ssr: false,
});

interface IError {
  statusCode: number;
  message?: string;
}

interface IProps {
  error: IError | null;
}

export const getFixedQueryParams = (params: querystring.ParsedUrlQuery) => {
  const { [ESearchFilters.DATE_FROM]: from, [ESearchFilters.DATE_TO]: to, ...otherParams } = params;
  if (from && to && typeof from === 'string' && typeof to === 'string') {
    const dateFrom = new Date(from);
    const dateTo = new Date(to);
    if (
      isNaN(dateFrom.getTime()) ||
      isNaN(dateTo.getTime()) ||
      dateFrom.getTime() === dateTo.getTime()
    ) {
      return otherParams;
    }
    if (dateFrom < dateTo) {
      return {
        ...otherParams,
        [ESearchFilters.DATE_FROM]: from,
        [ESearchFilters.DATE_TO]: to,
      };
    } else {
      return {
        ...otherParams,
        [ESearchFilters.DATE_FROM]: to,
        [ESearchFilters.DATE_TO]: from,
      };
    }
  }
  return otherParams;
};

const SearchPage: NextPage<IProps> = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const currency = useSelector(getCurrency);
  const searchMeta = useSelector(getSearchResultsMeta);
  const searchData = useSelector(getSearchViewedEventData);
  const [prevBaseUrl, { update: updatePrevBaseUrl }] = useLocalStorage('prevBaseUrl');
  const [expandMapView, setExpandMapView] = useState(false);
  const selectedFilter = useSelector(getSelectedFilter);
  const { isMobile, isAboveXL } = useBreakpoint();
  const filtersFromQuery = useSelector(getQueryParams);
  const rentalCategoryFromQuery =
    ((filtersFromQuery && filtersFromQuery[ESearchFilters.RENTAL_CATEGORY]) as ERentalCategory) ||
    ERentalCategory.RV;
  const isStay = rentalCategoryFromQuery === ERentalCategory.STAY;
  const isCampground = rentalCategoryFromQuery === ERentalCategory.CAMPGROUND;
  const newSearchFiltersEnabled = !isStay && !isCampground;

  const { headerHeight } = useAppLayout();

  const { stickyTopPositionRef } = useStickyToHeaderBottom(
    isMobile && !isStay && !isCampground ? `${headerHeight}px` : '-0.125rem',
  );
  const isFooterVisible = useFixedFooterVisibility();
  const singleTileGridExperiment = useExperimentIsEnabled(OptimizelyFlags.SINGLE_TILE_GRID);
  const singleTileGridEnabled = singleTileGridExperiment && isAboveXL && !isStay && !isCampground;
  const checkDrivableDistanceDecision = useOptimizelyDecision(
    OptimizelyFlags.CHECK_DRIVABLE_DISTANCE,
  );
  const priceTotalOnSrpDecision = useOptimizelyDecision(OptimizelyFlags.PRICE_TOTAL_ON_SRP);

  const toggleExpandMapView = useCallback(() => {
    trackSearchMapExpandedEvent({
      mapStatus: expandMapView ? EMapStatus.COLLAPSED : EMapStatus.EXPANDED,
    });
    setExpandMapView(isExpanded => !isExpanded);
  }, [expandMapView]);

  const isDesktopMapViewExpanded = expandMapView && isAboveXL;

  // The map defaults to hidden on tablet/mobile, but will automatically open on desktop
  // This is more complex than might be expected, because of SSR/client inconsistency
  const [isMapOpen, _setIsMapOpen] = useState(false);

  // Track whether the map has been toggled since init
  const setIsMapOpen = useCallback(
    (value: boolean) => {
      _setIsMapOpen(value);
    },
    [_setIsMapOpen],
  );
  // SSR doesn't know the window width, so listen to the window size hook until it's available on the client
  // and then open the map. But only if the map hasn't been toggled previously.
  const windowWidth = useWindowSize()?.width || 0;
  const isDesktop = windowWidth >= breakpoint.xl;

  const initialParams = useQueryParams();
  const previousParams = useRef<querystring.ParsedUrlQuery>();
  const searchTitle = initialParams.title;

  const checkDrivableDistanceEnabled = checkDrivableDistanceDecision?.variationKey === 'on';
  const isPriceTotalOnSrpEnabled = priceTotalOnSrpDecision?.variationKey === 'enabled';
  const checkDrivableDistanceQueryParamsEnabled =
    initialParams[ESearchFilters.CHECK_DRIVABLE_DISTANCE] === 'true';
  const checkPriceTotalOnSrpQueryParamsEnabled =
    initialParams[ESearchFilters.TOTAL_PRICE_ON_SRP_ENABLED] === 'true';

  useEffect(() => {
    // Skip if both feature flags match their URL params (no updates needed)
    // We now check each feature independently, allowing one to update even if the other is null
    const drivableDistanceMatches =
      !checkDrivableDistanceDecision ||
      checkDrivableDistanceEnabled === checkDrivableDistanceQueryParamsEnabled;

    const priceTotalMatches =
      !priceTotalOnSrpDecision ||
      isPriceTotalOnSrpEnabled === checkPriceTotalOnSrpQueryParamsEnabled;

    // Only return early if both features are in sync (or disabled)
    if (drivableDistanceMatches && priceTotalMatches) {
      return;
    }

    const filters: { [key: string]: string } = {};

    // Only update drivable distance filter if the decision exists and needs updating
    if (
      checkDrivableDistanceDecision &&
      checkDrivableDistanceEnabled !== checkDrivableDistanceQueryParamsEnabled
    ) {
      filters[ESearchFilters.CHECK_DRIVABLE_DISTANCE] = checkDrivableDistanceEnabled
        ? 'true'
        : 'false';
    }

    // Update price total filter if it exists and needs updating
    if (
      priceTotalOnSrpDecision &&
      isPriceTotalOnSrpEnabled !== checkPriceTotalOnSrpQueryParamsEnabled
    ) {
      filters[ESearchFilters.TOTAL_PRICE_ON_SRP_ENABLED] = isPriceTotalOnSrpEnabled
        ? 'true'
        : 'false';
    }

    dispatch(applySearchFilter(filters));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkDrivableDistanceDecision,
    checkDrivableDistanceEnabled,
    checkDrivableDistanceQueryParamsEnabled,
    priceTotalOnSrpDecision,
    isPriceTotalOnSrpEnabled,
    checkPriceTotalOnSrpQueryParamsEnabled,
  ]);

  // Track previous date values to detect changes
  const prevDatesRef = useRef({
    hadDates: false,
  });

  useEffect(() => {
    if (!priceTotalOnSrpDecision || !isPriceTotalOnSrpEnabled) return;

    const hasPriceFilter =
      initialParams[ESearchFilters.PRICE_MIN] || initialParams[ESearchFilters.PRICE_MAX];
    const currentFrom = filtersFromQuery?.[ESearchFilters.DATE_FROM];
    const currentTo = filtersFromQuery?.[ESearchFilters.DATE_TO];
    const hasDates = !!(currentFrom && currentTo);

    // Check if dates were added or removed
    const datesAdded = !prevDatesRef.current.hadDates && hasDates;
    const datesRemoved = prevDatesRef.current.hadDates && !hasDates;
    const datesChanged = datesAdded || datesRemoved;

    // Reset price filters when dates are added or removed, but only if we have price filters set
    if (datesChanged && hasPriceFilter) {
      dispatch(
        applySearchFilter({
          [ESearchFilters.PRICE_MIN]: undefined,
          [ESearchFilters.PRICE_MAX]: undefined,
        }),
      );
    }

    // Update our tracking ref
    prevDatesRef.current.hadDates = hasDates;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPriceTotalOnSrpEnabled,
    filtersFromQuery?.[ESearchFilters.DATE_FROM],
    filtersFromQuery?.[ESearchFilters.DATE_TO],
  ]);

  useEffect(() => {
    const fixedParams = getFixedQueryParams(initialParams);
    if (Object.keys(fixedParams).length > 0) {
      router.replace({ query: fixedParams });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Request data only on the client so we get querystrings and user's ip address
  useEffect(() => {
    const params = getFixedQueryParams(initialParams) || initialParams;

    dispatch(getSearchResults(params, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  useEffect(() => {
    if (selectedFilter === 'Price' || (isMobile && selectedFilter === 'More')) {
      const params = getFixedQueryParams(initialParams) || initialParams;
      dispatch(getSearchHistogramResults(params, undefined, isPriceTotalOnSrpEnabled));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, isMobile]);

  useEffect(() => {
    if (isDesktop || expandMapView) {
      setIsMapOpen(true);
      return;
    }

    setIsMapOpen(false);
  }, [expandMapView, isDesktop, setIsMapOpen]);

  const resultsSectionDisplay = isMapOpen ? 'hidden xl:block' : 'block';
  // Mobile: scroll back to the top (no toggle knob animation so no need to delay)
  const onMobileToggleMap = useCallback(() => {
    typeof window === 'object' && window.scrollTo({ top: 0, behavior: 'smooth' });
    const isEnabled = !isMapOpen;
    // the map should be expanded when user is in map view on MD/SM
    // breakpoint and resizes to LG
    setExpandMapView(isEnabled);
    setIsMapOpen(isEnabled);
    trackMapToggleEvent({ isEnabled });
    trackEvent({
      event: 'search/map',
      action: 'toggle',
      before: isMapOpen,
      after: isEnabled,
    });
  }, [isMapOpen, setIsMapOpen]);
  const mobileViewButtonTestId = isMapOpen ? 'search-list-btn' : 'search-map-btn';

  const eventData = useSelector(getPageViewEventData);

  useEffect(() => {
    if (eventData && searchData) {
      // Check if there is change in the query params in order to determinate if user
      // actually applies the selected filters and sees the result.
      if (isEqual(previousParams.current, initialParams)) {
        return;
      }
      previousParams.current = initialParams;

      const queryParms = eventData.queryParams;
      const {
        [ESearchFilters.PAGE_LIMIT]: pageLimit,
        [ESearchFilters.PAGE_OFFSET]: pageOffset,
        [ESearchFilters.ADDRESS]: queryAddress,
        [ESearchFilters.RENTAL_CATEGORY]: rentalCategory,
      } = queryParms;
      const offset = getParamAsString(pageOffset) ?? '';
      const limit = getParamAsString(pageLimit) ?? '';
      const address = getParamAsString(queryAddress) ?? '';
      const page = Math.ceil(Number(offset) / Number(limit)) + 1 || 1;
      const city = searchMeta.city;
      const state = searchMeta.state;
      const countryName = searchMeta.countryName;

      const searcherGeoLocation = [city, state, countryName].filter(Boolean).join(', ');

      if (!address) {
        if (city || state || countryName) {
          queryParms[ESearchFilters.GEOIP_ADDRESS] = searcherGeoLocation;
        }
      }

      lastZoomDirection(null);
      lastPaginationDelta(null);
      setFilterVehiclesShow(null);
      lastSearchedInSearchBar(null);
      lastMapPan(null);

      trackEvent({
        event: 'Search Results',
        queryParams: eventData.queryParams,
      });
      trackEvent({
        ...eventData,
        event: 'Searched for a rental',
        page,
        filters: queryParms,
        rental_category: rentalCategory || 'rv',
      });

      let searchQuery = '';
      const queryParams = (window && window.location.href.split('?')?.[1]) || '';
      if (queryParams) {
        searchQuery = `?${queryParams}`;
      }

      trackSearchEvent({
        ...searchData,
        searchQuery,
        geoLocation: address || searcherGeoLocation || null,
        searcherGeoLocation: searcherGeoLocation || null,
        isSEO: prevBaseUrl ? SERP_URLS.includes(prevBaseUrl) : false,
        experiments: searchMeta.experiments,
        experimentsData: searchMeta.experimentsData,
        isBlended: searchMeta.isBlended,
        searchFilterCategory: (rentalCategory as ERentalType) || ERentalType.RV,
        mapStatus: isAboveXL
          ? isDesktopMapViewExpanded
            ? EMapStatus.EXPANDED
            : EMapStatus.COLLAPSED
          : null,
      });
      updatePrevBaseUrl('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAboveXL, eventData, searchData]);

  const mobileToggleMapButton = (
    <Button
      className="shadow-600"
      data-testid={mobileViewButtonTestId}
      variant={ButtonVariants.whiteContained}
      onClick={onMobileToggleMap}>
      <span className="text-gray-900">
        {isMapOpen ? (
          <FormattedMessage defaultMessage="List view" id="wkS+Z1" />
        ) : (
          <FormattedMessage defaultMessage="Map view" id="fKRVP6" />
        )}
      </span>
      <Icon className="text-gray-900" name={isMapOpen ? LIST : MAP_PIN} />
    </Button>
  );

  return (
    <ErrorBoundary>
      <main className="text-gray-900 text">
        <Head />
        {/* Mobile map/list view button */}
        <div
          className={`fixed z-30 flex justify-center w-full select-none ${
            isFooterVisible ? 'bottom-[5rem]' : 'bottom-7'
          }`}>
          <div className="flex flex-row items-center xl:hidden gap-x-3">
            {mobileToggleMapButton}
          </div>
        </div>
        <div
          className="sticky z-40 w-full overflow-hidden bg-white md:z-30 border-y border-neutral-20"
          ref={stickyTopPositionRef}>
          <div className="flex items-center px-4 overflow-auto xxxl:mx-12 scrollbar-none">
            <div className="md:overflow-auto md:whitespace-nowrap">
              {newSearchFiltersEnabled ? <FilterPillsWrapper /> : null}
            </div>
          </div>
        </div>

        <SearchResultsCtx>
          <div
            className={`relative flex ${
              !isDesktopMapViewExpanded && 'xxxl:mx-16 px-4 sm:px-10 xxxl:px-0'
            }`}>
            {!isDesktopMapViewExpanded && (
              <section
                className={`${resultsSectionDisplay} w-full mt-4 sm:mt-10 md:mt-0 max-w-80 ${
                  isMapOpen
                    ? clsx({
                        'xl:w-8/12 xxl:w-3/4 xl:pr-10': !singleTileGridEnabled,
                        'xl:w-3/5 xxl:w-3/5 xxxl:w-3/5 xl:pr-10': singleTileGridEnabled,
                      })
                    : 'mx-auto'
                }`}>
                {
                  <>
                    <div className="flex items-center justify-between pb-5 xs:hidden md:flex">
                      <div className="hidden md:block">
                        {searchTitle && (
                          <Heading
                            className="mb-2 text-2xl text-gray-800 highlight"
                            data-testid="search-title"
                            level={1}>
                            {searchTitle}
                          </Heading>
                        )}
                      </div>
                    </div>
                    {searchTitle && (
                      <Heading
                        className="mb-4 text-base text-gray-800 md:hidden highlight"
                        level={1}>
                        {searchTitle}
                      </Heading>
                    )}
                  </>
                }
                {newSearchFiltersEnabled && (
                  <div className="pb-5">
                    <ResultsSort />
                  </div>
                )}
                <SearchResultsContainer isMapOpen={isMapOpen} />
              </section>
            )}
            {/*
          Remove the map from the DOM instead of hiding it, otherwise the map breaks the page.
          Apparently the map is confused when trying to render into something without dimensions.
          This also lightens the initial mobile page load: MapBox isn't loaded/rendered until the user clicks to show it.
          */}
            {isMapOpen && (
              <section
                style={
                  {
                    '--header-height': `${headerHeight + (stickyTopPositionRef.current?.clientHeight || 0)}px`,
                  } as React.CSSProperties
                }
                className={
                  isDesktopMapViewExpanded
                    ? 'h-screen bg-gray-100 xl:sticky xl:top-16 flex-1'
                    : `h-screen bg-gray-100 -mx-4 xl:sticky xl:top-[var(--header-height)] xl:ml-0 xl:-mr-10 xxxl:-mr-16 flex-1`
                }>
                <div className="h-full">
                  <SearchMapContainer
                    isDesktopMapViewExpanded={isDesktopMapViewExpanded}
                    toggleExpandMapView={toggleExpandMapView}
                  />
                </div>
              </section>
            )}
          </div>
        </SearchResultsCtx>
        {!isMapOpen && <FixedFooter />}
      </main>
    </ErrorBoundary>
  );
};

export default SearchPage;
