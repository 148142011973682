export enum EPromoBannerEventName {
  PROMO_BANNER_VIEWED = 'Banner Viewed',
  PROMO_BANNER_CLICKED = 'Banner Clicked',
}

export interface IPromoBannerEventInfo {
  bannerText: string;
  bannerCTA: string;
  bannerLink?: string | null;
  bannerName: string;
  source: string;
  odn_id?: string;
}
