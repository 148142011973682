import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ESearchFilters } from '@/constants/searchFilters';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { getOwnerInfo, unsetSearchFilter } from '@/redux/modules/search';
import { getFlexibleDateRangeSelected, getQueryParams } from '@/redux/selectors/queryParams';
import { getOwnerName } from '@/redux/selectors/search/owner';
import { getSearchResults } from '@/redux/selectors/search/searchResults';
import {
  trackListingImageViewedEvent,
  trackListingSelectedEvent,
} from '@/services/analytics/listings';
import { EListingSource } from '@/services/analytics/listings/types';
import { ERentalType } from '@/services/analytics/types';
import { trackEvent } from '@/services/track-event';
import { ISearchResultTile } from '@/utility/mapSearchResultToTile';
import { getParamAsString } from '@/utility/queryParams';

import SearchResults from './SearchResults';

interface IProps {
  isMapOpen?: boolean;
  promoSlug?: string;
  isPromoPage?: boolean;
}

const SearchResultsContainer: React.FC<IProps> = ({ isMapOpen, promoSlug, isPromoPage }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const searchResult = useSelector(getSearchResults);
  const { isMobile } = useBreakpoint();

  const flexibleDateRangeSelected = useSelector(getFlexibleDateRangeSelected);
  const queryParams = useSelector(getQueryParams);
  const {
    [ESearchFilters.OWNER_ID]: ownerId,
    [ESearchFilters.PAGE_LIMIT]: pageLimit,
    [ESearchFilters.PAGE_OFFSET]: pageOffset,
    [ESearchFilters.RENTAL_CATEGORY]: originalRentalType,
  } = queryParams;
  const defaultOwnerName = intl.formatMessage({
    defaultMessage: 'one Pro Dealer',
    id: 'SISM4/',
    description:
      "SearchResultsContainer - default name on dealer results message if owner's name request on users API fail",
  });
  const { name: ownerName, error: ownerNameError } = useSelector(getOwnerName);

  const offset = getParamAsString(pageOffset) ?? '';
  const limit = getParamAsString(pageLimit) ?? '';
  const page = Math.ceil(Number(offset) / Number(limit)) + 1 || 1;

  // Set host name
  useEffect(() => {
    dispatch(getOwnerInfo(ownerId?.toString()));
  }, [ownerId, dispatch]);

  // Reset host filter
  const handleOwnerButtonClick = () => {
    ownerId && dispatch(unsetSearchFilter([ESearchFilters.OWNER_ID]));
  };

  // Use default host name in case of an error while getting host information
  const parsedOwnerName = ownerNameError ? defaultOwnerName : ownerName || '';

  const onClickRental = useCallback(
    (rental: ISearchResultTile, index: number) => {
      const listingView = isMobile ? 'mobile-listing' : 'desktop-listing';

      trackEvent({
        event: 'click/card/rental',
        rental_id: rental.id,
        source: listingView,
        page,
        page_rank: index + 1,
        filters: queryParams,
      });

      if (rental?.id) {
        trackListingSelectedEvent({
          rental,
          eventSource: promoSlug ? EListingSource.PROMO : EListingSource.SEARCH_LISTING,
          listingPlacement: index + 1,
          currentPageViewed: page,
          flexibleDateRange: flexibleDateRangeSelected,
          promoSource: promoSlug || null,
        });
      }
    },
    [page, queryParams, isMobile, flexibleDateRangeSelected, promoSlug],
  );

  const onChangeImage = useCallback(
    (rental: ISearchResultTile, index: number, nextIndex: number) => {
      if (rental?.id) {
        trackListingImageViewedEvent(rental, 'search_listing', index + 1, nextIndex + 1, page);
      }
    },
    [page],
  );

  return (
    <SearchResults
      searchResult={searchResult}
      isMapOpen={isMapOpen}
      isStayTab={originalRentalType === ERentalType.STAY}
      isCampgroundTab={originalRentalType === ERentalType.CAMPGROUND}
      ownerName={parsedOwnerName}
      onOwnerButtonClick={handleOwnerButtonClick}
      onClickRental={onClickRental}
      onChangeImage={onChangeImage}
      isPromoPage={isPromoPage}
      wishlistingEventData={{
        eventSource: EListingSource.SEARCH_LISTING,
        page,
      }}
    />
  );
};

export default SearchResultsContainer;
